import { useQuery } from "@apollo/client";
import { useContext, useEffect } from "react";

import { PageContext } from "../context/PageContext";

const useQueryImproved = (query, variables = {}) => {
  const page = useContext(PageContext);

  const { loading, data, error } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    variables,
  });

  useEffect(() => {
    page.setLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    loading,
    data,
    error,
  };
};

export default useQueryImproved;
