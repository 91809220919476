import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { gql } from "@apollo/client";

import {
  Card,
  Avatar,
  Layout,
  Stack,
  TextContainer,
  Page,
  ResourceItem,
  SkeletonDisplayText,
  ResourceList,
  SkeletonBodyText,
  SkeletonThumbnail,
  SkeletonPage,
  InlineError,
  Filters,
  TextStyle,
} from "@shopify/polaris";

import useThrottle from "../hooks/useThrottle";
import useQuery from "../hooks/useQuery";

const GET_INSTALLATION = gql`
  query getInstallation($id: String!, $searchQuery: String) {
    installation(id: $id) {
      id
      name
      avatarUrl
      assets(searchQuery: $searchQuery) {
        totalCount
        nodes {
          id
          name
        }
      }
    }
  }
`;

const Assets = () => {
  const { installationId } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const searchQueryThrottled = useThrottle(searchQuery);

  const {
    loading,
    error,
    data = { installation: {} },
  } = useQuery(GET_INSTALLATION, {
    id: installationId,
    searchQuery: searchQueryThrottled,
  });

  useEffect(() => {
    if (!loading && data && data.installation) {
      setFirstLoad(false);
    }
  }, [loading, data]);

  const {
    installation: { assets = { totalCount: 0, nodes: [] } },
  } = data;

  const resourceName = {
    singular: "asset",
    plural: "assets",
  };

  const filterControl = (
    <Filters
      queryValue={searchQuery}
      filters={[]}
      onQueryChange={useCallback((v) => setSearchQuery(v), [])}
      onQueryClear={() => {
        console.log("x");
      }}
      onClearAll={() => {
        console.log("p");
      }}
    />
  );

  console.log(error, data);

  return loading && firstLoad ? (
    <SkeletonPage title={installationId} primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </TextContainer>
          </Card>

          <Card subdued>
            {[1, 2, 3, 4].map((i) => {
              return (
                <Card.Section id={i} key={i}>
                  <Stack>
                    <SkeletonThumbnail size="small" />
                    <Stack.Item fill>
                      <SkeletonBodyText lines={1} />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              );
            })}
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ) : (
    <Page
      title={installationId}
      primaryAction={{ content: "Configure", disabled: true }}
    >
      {error && (
        <InlineError message="Error to fetch installations data, try again later" />
      )}

      {!error && (
        <Layout>
          <Layout.Section>
            {/* <Card title="Online store dashboard" sectioned> */}
            {/*   <TextContainer> */}
            {/*     <p>View a summary of your online store’s performance.</p> */}
            {/*   </TextContainer> */}
            {/* </Card> */}
            <Card title="Assets">
              <ResourceList
                loading={loading}
                filterControl={filterControl}
                resourceName={resourceName}
                items={assets.nodes}
                renderItem={({ id: rid, name, versions: { totalCount } }) => {
                  return (
                    <ResourceItem
                      id={rid}
                      url={`/installations/${installationId}/assets/${name}`}
                      verticalAlignment="center"
                      media={
                        <Avatar
                          size="medium"
                          shape="square"
                          initials={name[0].toUpperCase()}
                        />
                      }
                      accessibilityLabel={`View details for ${name}`}
                    >
                      <h3>
                        <TextStyle variation="strong">{name}</TextStyle>
                      </h3>
                      {totalCount} assets
                    </ResourceItem>
                  );
                }}
              ></ResourceList>
            </Card>
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
};

export default Assets;
