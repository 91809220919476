import { Navigation } from "@shopify/polaris";
import React from "react";

import { Outlet, useParams } from "react-router-dom";
import { ArrowLeftMinor, HomeMajor, OrdersMajor } from "@shopify/polaris-icons";
import Frame from "./Frame";

const OnInstallationRoutes = () => {
  const { installationId } = useParams();

  const navigation = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: "Installations",
            icon: ArrowLeftMinor,
            url: "/installations",
          },
        ]}
      />
      <Navigation.Section
        separator
        title={installationId}
        items={[
          {
            label: "Projects",
            icon: HomeMajor,
            url: `/installations/${installationId}/projects`,
          },
          {
            label: "Assets",
            icon: OrdersMajor,
            url: `/installations/${installationId}/assets`,
          },
        ]}
      />
    </Navigation>
  );

  return (
    <Frame navigation={navigation}>
      <Outlet />
    </Frame>
  );
};

export default OnInstallationRoutes;
