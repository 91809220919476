import React, { useState, useMemo } from "react";

export const InstallationContext = React.createContext();

export const InstallationProvider = ({ children }) => {
  const [currentInstallation, setCurrentInstallation] = useState(null);

  const value = useMemo(
    () => ({
      currentInstallation,
      setCurrentInstallation,
    }),
    [currentInstallation] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <InstallationContext.Provider value={value}>
      {children}
    </InstallationContext.Provider>
  );
};
