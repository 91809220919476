import React from "react";
import ReactDOM from "react-dom";

import "@shopify/polaris/build/esm/styles.css";

import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";

import App from "./App";

import { PageProvider } from "./context/PageContext";

ReactDOM.render(
  <AppProvider i18n={enTranslations}>
    <PageProvider>
      <App />
    </PageProvider>
  </AppProvider>,
  document.getElementById("root")
);
