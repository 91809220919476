import React, { useState } from "react";

const PageContext = React.createContext({
  loading: false,
  setLoading: null,
});

function PageProvider({ children }) {
  const [loading, setLoading] = useState(false);

  return (
    <PageContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}

export { PageContext, PageProvider };
