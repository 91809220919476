import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { gql } from "@apollo/client";

import {
  Card,
  Avatar,
  Layout,
  DataTable,
  Stack,
  TextContainer,
  Pagination,
  Page,
  Spinner,
  ResourceItem,
  SkeletonDisplayText,
  ResourceList,
  SkeletonBodyText,
  SkeletonThumbnail,
  SkeletonPage,
  InlineError,
  Filters,
  TextStyle,
} from "@shopify/polaris";

import useQuery from "../hooks/useQuery";

const GET_REPOSITORY = gql`
  query getRepository($id: String!, $page: Int) {
    repository(id: $id) {
      id
      name
      assets(page: $page) {
        totalCount
        hasNextPage
        nodes {
          name
          version
        }
      }
    }
  }
`;

const Repository = () => {
  const { projectId, installationId } = useParams();
  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const {
    loading,
    data = { repository: { assets: { nodes: [] } } },
    error,
  } = useQuery(GET_REPOSITORY, {
    id: projectId,
    page: page,
  });

  const { repository } = data;
  const assets = repository.assets || { nodes: [] };

  const rows = assets.nodes.map((asset) => [asset.name, asset.version]);

  useEffect(() => {
    if (!loading && firstLoad) {
      setFirstLoad(false);
    }
  }, [loading]);

  return loading && firstLoad ? (
    <SkeletonPage title={projectId} primaryAction breadcrumbs>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </TextContainer>
          </Card>

          <Card subdued>
            {[1, 2, 3, 4].map((i) => {
              return (
                <Card.Section id={i} key={i}>
                  <Stack>
                    <SkeletonThumbnail size="small" />
                    <Stack.Item fill>
                      <SkeletonBodyText lines={1} />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
              );
            })}
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ) : (
    <Page
      title={projectId}
      primaryAction={{ content: "Configure", disabled: true }}
      breadcrumbs={[
        { url: "/installations" },
        { url: `/installations/${installationId}` },
      ]}
      pagination={{
        hasPrevious: page > 1,
        hasNext: assets.hasNextPage,
        onPrevious: () => setPage(page - 1),
        onNext: () => setPage(page + 1),
      }}
    >
      <Card>
        <DataTable
          columnContentTypes={["text", "text"]}
          headings={[<strong>Asset</strong>, <strong>Version</strong>]}
          rows={rows}
          footerContent={loading && <Spinner />}
        />
      </Card>
    </Page>
  );
};

export default Repository;
