import React, { useState, useCallback } from "react";

import { Frame as PolarisFrame, TopBar, ActionList } from "@shopify/polaris";

import LoadingBar from "./LoadingBar";

import Logo from "../assets/img/Logo.svg";

const Frame = ({ children, navigation }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const logo = {
    url: "/installations",
    width: 50,
    topBarSource: Logo,
    contextualSaveBarSource: Logo,
    accessibilityLabel: "Warehouse",
  };

  const [searchValue, setSearchValue] = useState("");
  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const userMenuActions = [
    {
      items: [{ content: "Community forums" }],
    },
  ];

  const handleSearchFieldChange = useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue("");
  }, []);

  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name="User"
      detail={"accounts"}
      initials="U"
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={[
        { content: "Shopify help center" },
        { content: "Community forums" },
      ]}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchFieldChange}
      value={searchValue}
      placeholder="Search"
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      // userMenu={userMenuMarkup}
      // searchResultsVisible={searchActive}
      // searchField={searchFieldMarkup}
      // searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  return (
    <PolarisFrame logo={logo} topBar={topBarMarkup} navigation={navigation}>
      <LoadingBar />
      {children}
    </PolarisFrame>
  );
};

export default Frame;
