import React, { useContext } from "react";
import { PageContext } from "../context/PageContext";

import { Loading } from "@shopify/polaris";

const LoadingBar = () => {
  const page = useContext(PageContext);

  if (page.loading) {
    return <Loading />;
  }

  return null;
};

export default LoadingBar;
